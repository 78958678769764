<template>
  <div id="add-sma-mannual-payment">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-row wrap>
          <v-col align="right" cols="12" md="12">
            <v-btn
              medium
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
              >&nbsp; Refresh &nbsp;
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- <v-card-subtitle class="text-h5">
            <h4>{{ PageDescription }}</h4>
          </v-card-subtitle> -->
      <v-card-text>
        <v-card class="pa-4">
          <v-container>
            <v-row class="text-center font-weight-bold">
              <v-col cols="3">Plan</v-col>
              <v-col cols="3">Paid Qty</v-col>
              <v-col cols="3">Remaining Qty</v-col>
              <v-col cols="3">Amount</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="text-center">
              <v-col cols="3">{{ records.PlanName }}</v-col>
              <v-col cols="3">{{ records.PaidCount }}</v-col>
              <v-col cols="3">{{ records.RemainingCount }}</v-col>
              <v-col cols="3">{{ records.Amount }}</v-col>
            </v-row>
          </v-container>
        </v-card>
        <br />
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container class="py-0">
            <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
              <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
            <v-row v-if="SeniorMemberType > 0">
              <h6 class="text-dark">
                <span class="text-danger">*</span> Notes:
              </h6>
              <v-col
                cols="12"
                sm="12"
                lg="12"
                md="12"
                v-if="SeniorMemberType == 1"
              >
                <h6>1. The member type should be a "SMA member"</h6>
                <!-- <br /> -->
                <h6>
                  2. Should not have already renewed the membership for the
                  selected year
                </h6>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                lg="12"
                md="12"
                v-if="SeniorMemberType == 2"
              >
                <h6>1. The member type should be an "Active member"</h6>
                <h6>2. Should not be a selected year member</h6>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                lg="12"
                md="12"
                v-if="SeniorMemberType == 3"
              >
                <h6>
                  1. With the details given, a new SMA membership ID will be
                  created and sent to the member via SMS and email
                </h6>
              </v-col>
            </v-row>
            <v-row wrap class="d-flex align-center">
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Senior Member Type</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="SeniorMemberTypeOptions"
                  :rules="SeniorMemberTypeRules"
                  v-model="SeniorMemberType"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="SeniorMemberType == 1 || SeniorMemberType == 2"
                cols="12"
                sm="6"
                lg="4"
                md="6"
              >
                <h6><span class="text-danger">* </span> Membership Id</h6>
                <v-text-field
                  v-model="MembershipId"
                  placeholder="Enter Membership Id"
                  :rules="MembershipIdRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <v-btn
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  elevation="30"
                  shaped
                  small
                  color="primary"
                  class="white--text"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>

            <v-row wrap class="mt-0 px-4" v-if="SeniorMemberType == 3">
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> LO</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> First Name</h6>
                <v-text-field
                  v-model="FirstName"
                  :rules="FirstNameRules"
                  placeholder="Enter First Name"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Last Name</h6>
                <v-text-field
                  v-model="LastName"
                  :rules="LastNameRules"
                  placeholder="Enter Last Name"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Old MembershipId <small>(optional)</small></h6>
                <v-text-field
                  v-model="OldMembershipId"
                  :rules="OldMembershipIdRules"
                  placeholder="Enter Membership Id (optional)"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Gender</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="GenderOptions"
                  :rules="GenderRules"
                  v-model="Gender"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Email</h6>
                <v-text-field
                  v-model="EmailId"
                  :rules="EmailIdRules"
                  placeholder="Enter EmailId"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Mobile Number</h6>
                <v-text-field
                  v-model="MobileNo"
                  :rules="MobileNoRules"
                  :counter="10"
                  v-mask="'##########'"
                  type="number"
                  required
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> State</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="StateCodeOptionsLoading"
                  :items="StateCodeOptions"
                  :rules="StateCodeRules"
                  v-model="StateCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> District</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DistrictCodeOptionsLoading"
                  :items="DistrictCodeOptions"
                  :rules="DistrictCodeRules"
                  v-model="DistrictCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> City</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="CityCodeOptionsLoading"
                  :items="CityCodeOptions"
                  :rules="CityCodeRules"
                  v-model="CityCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Pincode</h6>
                <v-text-field
                  v-model="Pincode"
                  :rules="PincodeRules"
                  placeholder="Enter Pincode"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="8" md="12">
                <h6><span class="text-danger">* </span> Address</h6>
                <v-textarea
                  solo
                  outlined
                  placeholder="Enter Address"
                  required
                  v-model="Address"
                  :rules="AddressRules"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-container v-if="rows.MembershipId > 0">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12" lg="12" align="center">
                  <v-img
                    v-if="rows.MemberImage"
                    :src="rows.MemberImage"
                    :alt="rows.Fullname"
                    lazy-src="/no-image-available.png"
                    height="100px"
                    width="100px"
                  >
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Membership Id</h6>
                  <h6 class="text-dark">{{ rows.MembershipId }}</h6>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Member Name</h6>
                  <h6 class="text-dark">{{ rows.Fullname }}</h6>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Member Designation</h6>
                  <h6 class="text-dark">{{ rows.MemberDesignation }}</h6>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Member Zone</h6>
                  <h6 class="text-dark" v-if="SeniorMemberType == 2">
                    {{ records.ZoneName }}
                  </h6>
                  <h6 class="text-dark" v-if="SeniorMemberType == 1">
                    {{ rows.SmaZoneName }}
                  </h6>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Member Lom</h6>
                  <h6 class="text-dark">{{ rows.LomName }}</h6>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Member DOB</h6>
                  <h6 class="text-dark">{{ rows.DOB }}</h6>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Member Mobile No</h6>
                  <v-text-field
                    v-model="MobileNo"
                    :rules="MobileNoRules"
                    :counter="10"
                    v-mask="'##########'"
                    type="number"
                    required
                    dense
                    outlined
                  >
                  </v-text-field>
                  <!-- <h6 class="text-dark">{{ rows.MobileNo }}</h6> -->
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <h6>Member Email Id</h6>
                  <v-text-field
                    v-model="EmailId"
                    placeholder="Enter Email Id"
                    :rules="EmailIdRules"
                    required
                    outlined
                    dense
                  >
                  </v-text-field>
                  <!-- <h6 class="text-dark">{{ rows.EmailId }}</h6> -->
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3" v-if="rows.CityId == ''">
                  <h6>Member City Name</h6>
                  <v-text-field
                    v-model="CityName"
                    placeholder="Enter City Name"
                    :rules="CityNameRules"
                    required
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h6>Member Address</h6>
                  <h6 class="text-dark">{{ rows.AddressTxt }}</h6>
                </v-col>
              </v-row>
            </v-container>
          </v-container>

          <v-container>
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12" align="center">
                <v-btn
                  v-if="ResultFlag"
                  :disabled="!valid1"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  elevation="30"
                  shaped
                  tile
                  medium
                  color="success"
                  class="font-size-h6 px-6 py-4 mt-6 white--text"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
    
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      SmOrderId: "",
      valid1: true,
      search: "",
      uploadPercentage: 0,
      OverlayLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      LoadingFlag: false,
      ResultFlag: false,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],

      SeniorMemberTypeOptions: [
        { value: 3, text: "Non-Member" },
        { value: 2, text: "JCI India Member" },
        { value: 1, text: "SMA Renewal" },
      ],
      SeniorMemberTypeRules: [(v) => !!v || "Senior Member Type is required"],
      SeniorMemberType: "",

      MembershipIdRules: [(v) => !!v || "Membership Id is required"],
      MembershipId: "",

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "LO is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      FirstName: "",
      FirstNameRules: [(v) => !!v || "First Name is required"],

      LastName: "",
      LastNameRules: [(v) => !!v || "Last Name is required"],

      OldMembershipId: "",
      OldMembershipIdRules: [],

      EmailId: "",
      EmailIdRules: [
        (v) => !!v || "Email Id is required",
        (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
      ],

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile Number is required"],

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      CityName: "",
      CityNameRules: [(v) => !!v || "City Name is required"],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      Address: "",
      AddressRules: [(v) => !!v || "Address is required"],

      GenderOptions: [
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Other" },
      ],
      GenderRules: [(v) => !!v || "Gender is required"],
      Gender: "",

      PaidDate: "",
      PaidDateRules: [(v) => !!v || "Paid Date is required"],
      menu1: false,

      TransactionType: "",
      TransactionTypeRules: [(v) => !!v || "Transaction Type is required"],
      TransactionTypeOptions: [],
      TransactionTypeOptionsLoading: false,

      PaymentId: "",
      PaymentIdRules: [(v) => !!v || "Payment Id is required"],

      // TransactionFees: 0,
      // TransactionFeesRules: [],

      SmPlan: "",
      SmPlanRules: [(v) => !!v || "Plan is required"],
      SmPlanOptions: [],
      SmPlanOptionsLoading: false,

      rows: {},

      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      selected1: [],

      tableData: [],
      tableColumns: [],
      tableOptions: {},
      selected: [],
      records: [],
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      ZoneId: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var LomCode = this.$session.get("LomId");
        this.LomCode = LomCode == (null || undefined) ? 0 : LomCode;
        console.log("LomCode=" + LomCode);

        var ZoneCode = this.$session.get("ZoneId");
        this.ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        this.ZoneCode = ZoneCode;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions called");
      this.LomCodeOptionsLoading = false;
    },
    TransactionTypeOptions: function () {
      console.log("watch TransactionTypeOptions");
      this.TransactionTypeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode called");
      this.getLomCodeOptions();
    },
    PaidDate: function () {
      console.log("watch PaidDate called");
      this.SmPlan = "";
      this.getSmPlanOptions();
    },
    SeniorMemberType: function () {
      console.log("watch SeniorMemberType called");
      var seniorMemberType = this.SeniorMemberType;
      console.log({ seniorMemberType });
      this.MembershipId = "";
      this.ResultFlag = seniorMemberType == 3 ? true : false;
      this.resetForm();
      this.resetAll();
    },
    MembershipId: function () {
      console.log("watch MembershipId called");
      // this.resetForm();
      // this.ResultFlag = false;
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.StateCodeOptionsLoading = false;
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.CityCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.getDistrictCodeOptions();
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.getCityCodeOptions();
    },
    rows: function () {
      console.log("watch rows called");
      this.MobileNo = this.rows.MobileNo;
      this.EmailId = this.rows.EmailId;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "sma_receipt",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.searchForm();
    },
    resetForm() {
      console.log("resetForm called.");
      this.rows = {};
      this.PaidDate = "";
      this.TransactionType = "";
      this.PaymentId = "";
      this.SmPlan = "";
      this.TransactionFees = 0;
    },
    resetAll() {
      console.log("resetAll called");
      this.ZoneCode = "";
      this.LomCode = "";
      this.FirstName = "";
      this.LastName = "";
      this.MembershipId = "";
      this.OldMembershipId = "";
      this.Gender = "";
      this.EmailId = "";
      this.MobileNo = "";
      this.StateCode = "";
      this.DistrictCode = "";
      this.CityCode = "";
      this.Pincode = "";
      this.Address = "";
    },
    pageData() {
      console.log("pageData called");
      this.getZoneCodeOptions();
      this.getTransactionTypeOptions();
      this.getStateCodeOptions();
      this.getTableData();
    },
    getSmPlanOptions() {
      console.log("getSmPlanOptions called");
      var message = "";
      var PaidDate = this.PaidDate;
      this.SmPlanOptions = [];
      if (PaidDate != "") {
        this.SmPlanOptionsLoading = true;
        var selectbox1_source = "SmPlan";
        var selectbox1_destination = "SmPlanOptions";
        var selectbox1_url = "api/sma/plan/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          PaidDate: PaidDate,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        message += "Paid date should not be empty. ";
        // this.sweetAlert("error", message, false);
      }
    },
    getTableData() {
      console.log(" getTableData is called");
      var SmOrderId = this.$route.query.id;

      if (SmOrderId != "") {
        var upload = {
          UserInterface: 1,
          SmOrderId: SmOrderId,
        };
        console.log({ upload });

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior/members/receipt/show";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log(`output=${output}, flag=${flag}, records=`, records);
            if (flag == 1) {
              thisIns.records = records;
              thisIns.ZoneId = records.ZoneId;
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      }
    },
    getZoneCodeOptions() {
      this.ZoneCodeOptions = [];
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.CurrentYearId;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getTransactionTypeOptions() {
      console.log("getTransactionTypeOptions called");
      this.TransactionTypeOptionsLoading = true;
      var selectbox1_source = "TransactionType";
      var selectbox1_destination = "TransactionTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 3,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getStateCodeOptions() {
      console.log("getStateCodeOptions called");
      this.StateCodeOptionsLoading = true;
      var selectbox1_source = "StateCode";
      var selectbox1_destination = "StateCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      var StateCode = this.StateCode;
      console.log({ StateCode });
      if (StateCode != "") {
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");
      var DistrictCode = this.DistrictCode;
      console.log({ DistrictCode });
      if (DistrictCode != "") {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var SeniorMemberType = this.SeniorMemberType;
      var MembershipId = this.MembershipId;
      console.log({ SeniorMemberType }, { MembershipId });

      if (SeniorMemberType != "" && MembershipId != "") {
        this.LoadingFlag = true;
        this.SearchFlag = true;
        this.Member = {};
        this.rows = {};
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 9,
          MembershipId: this.MembershipId,
          MemberType: this.SeniorMemberType,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;
        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records;
              thisIns.ResultFlag = true;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Membership Id is required";
        this.toast("error", message);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      var paid_date = this.PaidDate;
      console.log({ validate1 }, { paid_date });

      var sma_type = this.SeniorMemberType;

      var flag =
        sma_type == 3
          ? true
          : sma_type == 2 || sma_type == 1
          ? this.ResultFlag
          : false;

      console.log({ flag });

      if (validate1 && flag) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (!validate1) {
          var message = "Kindly fill the required fields. ";
          this.sweetAlert("error", message, false);
        }
        if (!flag) {
          var message = "Kindly search the membership id first and submit it. ";
          this.sweetAlert("error", message, false);
        }
      }
    },
    submitForm() {
      console.log("submitForm called");

      var validate1 = this.$refs.form1.validate();
      var paid_date = this.PaidDate;
      console.log({ validate1 }, { paid_date });

      var sma_type = this.SeniorMemberType;
      var tr = this.rows;
      var old_membership_id =
        this.OldMembershipId != "" ? this.OldMembershipId : null;

      if (validate1) {
        this.LoadingFlag = true;
        this.SubmitFlag = true;
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/zone-chairman/add-member";
        var upload = {
          UserInterface: sma_type == 3 ? 1 : sma_type == 2 ? 2 : 3,
          SeniorMemberType: sma_type,
          Member: sma_type == 3 ? null : tr.MemberId,
          MembershipId: sma_type == 3 ? old_membership_id : tr.MembershipId,
          Zone: this.ZoneId,
          Lom: sma_type == 3 ? this.LomCode : tr.LomId,
          FirstName: sma_type == 3 ? this.FirstName : tr.FirstName,
          LastName: sma_type == 3 ? this.LastName : tr.LastName,
          Gender: sma_type == 3 ? this.Gender : tr.Gender,
          EmailId: this.EmailId,
          MobileNo: this.MobileNo,
          State: sma_type == 3 ? this.StateCode : tr.StateId,
          District: sma_type == 3 ? this.DistrictCode : tr.DistrictId,
          CityName: this.CityName == "" ? null : this.CityName,
          City: sma_type == 3 ? this.CityCode : tr.CityId,
          Pincode: sma_type == 3 ? this.Pincode : tr.Pincode,
          Address: sma_type == 3 ? this.Address : tr.AddressTxt,
          PaymentId: this.PaymentId,
          OrderId: this.SmOrderId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.resetAll();
              thisIns.SeniorMemberType = "";
              thisIns.ResultFlag = false;
              thisIns.rows = {};
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    console.log(this.$route.query.id);
    this.SmOrderId = this.$route.query.id;
    console.log("SmOrderId=" + this.SmOrderId);
    this.refreshPageData();
  },
};
</script>
    
    <style lang="scss"></style>
    